<template>
    <!-- /test/app-table/icons -->
    <div class="page-test-app-table-icons container">
        <div class="row">
            <div class="col-12">
                <app-table
                    class="icons-table"

                    title="Icons"

                    :cols="cols"
                    :rows="rows"

                    @details="onDetails"
                    @invoice="onInvoice"
                    @csv="onCSV"
                    @pdf="onPDF"
                    @remove="onRemove"
                ></app-table>
            </div>
        </div>
    </div>
</template>

<script>
import appTable from '@/components/app-table'

export default {
    components: {
        appTable,
    },

    data() {
        return {
            customer_uuid: 'ceee8826-692b-49be-ab1f-4aac7f48d3a1',
            provider_spid: '406a51a8-8f8a-41ee-0001',

            cols: [
                { key: 'details', title: 'Details', action: 'details', icon: 'link',                                                                             },
                { key: 'invoice', title: 'Invoice', action: 'invoice',                                                                                           },
                { key: 'csv',     title: 'CSV',     action: 'csv',     icon: 'invoice',                                                                          },
                { key: 'pdf',     title: 'PDF',     action: 'pdf',     icon: 'invoice', priority: 'primary',                                                     },
                { key: 'remove',  title: 'Remove',  action: 'remove',                                                                                            },

                // { key: 'link',    title: 'Link',                                                             type: 'link',                                       },
                { key: 'link',    title: 'Link',                       icon: 'invoice',                      type: 'link',                                       blank: true, },
                // { key: 'link',    title: 'Link',                       icon: 'invoice', priority: 'primary', type: 'link',                                       },

                // { key: 'route',   title: 'Route',                                                            type: 'route', route: { name: 'customer-details' }, },
                { key: 'route',   title: 'Route',                      icon: 'invoice',                      type: 'route', route: { name: 'customer-details' }, /* blank: false, */ },
                // { key: 'route',   title: 'Route',                      icon: 'invoice', priority: 'primary', type: 'route', route: { name: 'customer-details' }, },
            ],
            rows: [],
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.rows = [
                {
                    csv: {
                        disabled: true,
                    },

                    pdf: {
                        disabled: true,
                    },

                    link: 'https://github.com',

                    /*
                        route: {
                            params: {
                                uuid: this.customer_uuid,
                            },
                        },
                    */

                    route: {
                        name: 'provider',

                        params: {
                            spid: this.provider_spid,
                        },
                    },
                },
            ]
        },

        onDetails(row) {
            console.log('onDetails', row)
        },

        onInvoice(row) {
            console.log('onInvoice', row)
        },

        onCSV(row) {
            console.log('onCSV', row)
        },

        onPDF(row) {
            console.log('onPDF', row)
        },

        onRemove(row) {
            console.log('onRemove', row)
        },
    },
}
</script>

<style lang="scss">
.page-test-app-table-icons {
    .icons-table {
        margin: 48px 0;

        @include table-cols-width((128px, 128px, 128px, 128px, 128px, 128px, 128px), true);
    }
}
</style>